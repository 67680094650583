* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  margin: 0;
  padding: 0;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis--3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.swiper {
  width: 100%;
}
.customSwiper {
  width: 100%;
}
.swiper-button-next,
.swiper-button-prev {
  stroke: 0.7 !important;
  opacity: 0 - 1;
  color: #2600af !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 12px !important;
  font-weight: bolder;
}
.swiper-button-prev:after {
  left: 0px !important;
}
.swiper-button-next:after {
  right: 0px !important;
}
.swiper-button-disabled {
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

button:focus-visible {
  outline: 1px solid transparent !important;
}

.bottomBoxShadow {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
}

.defaultBoxShadow {
  box-shadow: 0px 11px 15px rgba(2, 36, 62, 0.2), 0px 24px 38px rgba(2, 36, 62, 0.14),
    0px 9px 46px rgba(2, 36, 62, 0.12) !important;
}

.active-link {
  background: rgba(217, 217, 217, 0.3);
}
.active-link > div {
  background: rgba(217, 217, 217, 0.3);
}

.normal-link {
  background: white;
}

.text-shadow-primary {
  text-shadow: -1px -1px 0 rgb(83 43 227 / 25%), 1px -1px 0 rgb(83 43 227 / 25%),
    -1px 1px 0 rgb(83 43 227 / 25%), 1px 1px 0 rgb(83 43 227 / 25%) !important;
}

#charitips_modal_container {
  z-index: 1300 !important;
}
#charitips_modal_container > .fyNOZG {
  top: 0 !important;
}

.purple-scrollbar {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: thin;
}

.purple-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.purple-scrollbar::-webkit-scrollbar-thumb {
  background-color: #532be3;
  border-radius: 12px;
}

.purple-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(220, 220, 227, 1);
}

.purple-scrollbar-chip-topic {
  max-width: 600px;
  overflow: auto;
}

.purple-scrollbar-chip-topic::-webkit-scrollbar {
  height: 0.5rem;
}

.purple-scrollbar-chip-topic::-webkit-scrollbar-thumb {
  background-color: #532be3;
  border-radius: 12px;
}

.purple-scrollbar-chip-topic::-webkit-scrollbar-track {
  background-color: rgba(220, 220, 227, 1);
}

.comments-container {
  margin-top: 13;
  overflow-y: auto;
}

.comments-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #eeeeee;
  border-radius: 50px;
}

.comments-container::-webkit-scrollbar-thumb {
  background-color: #532be3;
  border-radius: 50px;
}
