/*Colors*/
:root {
  --colors-light: #f8f7fe;
  --colors-purple: #532be3;
  --semantics-surface-secondary-background: var(--colors-light);
  --semantics-surface-secondary-text-secondary: var(--colors-purple);
}

/*Typographie*/
h1,
h2,
h3,
.h1,
.h2,
.h3,
.body-1,
.body-2,
.body-3,
.body-4,
.body-5,
.caption {
  font-family: 'Plus Jakarta Sans';
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-style: normal;
  line-height: normal;
}

h1,
.h1 {
  font-size: 2.8rem;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 1.6rem;
  font-weight: 700;
}

.body-1 {
  font-size: 1.6rem;
  font-weight: 700;
}

.body-2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.body-3 {
  font-size: 1.4rem;
  font-weight: 700;
}

.body-4 {
  font-size: 1.4rem;
  font-weight: 500;
}

.body-5 {
  font-size: 1.4rem;
  font-weight: 400;
}

.caption {
  font-size: 1.2rem;
  font-weight: 400;
}
